import { config } from '../config'
import { useTitle } from '../hooks/useTitle'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Experience() {

  useTitle('Experience')

  return (
    <div>

      <div className="container pt-5">

        <section className="my-5 mt-5 pt-2">

          <div className="text-start text-md-center mt-5">
            <h1 className="mb-3 fw-900 mt-0">Experience</h1>
            <p className="text mt-0 text-muted mb-0">I've gotten the opportunity to work with some of
              the most innovative and trusted companies, in helping to contribute to the
              development, design, and usability of new web and digital experiences. My work has
              been proven successful time and time again.</p>
          </div>

          <hr className="my-5" />


          <VerticalTimeline className="w-100" lineColor="#1a1a1a" layout="1-column-left">
            {config.experience.map(({ title, img, workplace, start, end, location, skills }, index) => (
              <VerticalTimelineElement
                contentStyle={{ background: '#1a1a1a', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid #1a1a1a' }}
                date={`${start} - ${end}`}
                icon={<img src={img} alt="Snap-on" className="img-fluid rounded-circle" />}
                iconStyle={{ boxShadow: '0 0 0 2.5px #fff' }}
                textClassName="p-4"
                key={index}
              >
                <h4 className="vertical-timeline-element-title mb-2">{title}</h4>
                <h6 className="text-muted mb-3" style={{fontWeight: 500}}>{workplace} -{location}</h6>
                {skills.map((skill, index) => (
                  <div className="d-flex" key={index}>
                    <div className="me-2 ni">✨</div>
                    <p className="mt-0 mb-2" style={{ fontWeight: 400 }}>{skill}</p>
                  </div>
                ))}
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>

        </section>

      </div>

    </div>
  )
}

export default Experience