import LOGO from '../assets/img/logo-white.svg';
import { config } from '../config';
import { Link } from 'react-router-dom';
import { Icon, NewTab } from '../components';
import Briefer from '../components/blocks/Briefer';

function Footer() {

    return (
        <div className="container p-0">
            <div className="my-5">
                <div className="border-top border-3 border-dark" />

                <Briefer />

                <div className="border-top border-3 border-dark" />

                <div className="mt-5 inquiry">
                    <div className="align-items-center">
                        <h2 className="subheading text-center mb-3 fw-700">
                            Have an inquiry or idea?<br />
                            <span className="text-span-8 gradient-text ni">Let's get in touch.</span>
                        </h2>

                        <div className="text-center mt-4">
                            <Link to="/contact" className="submit-button">
                                Get in touch  <Icon type="far" name="long-arrow-right" className="ms-2" />
                            </Link>
                        </div>

                    </div>
                </div>
            </div>

            <div className="border-top border-3 border-dark" />

            <div className="mt-4 mx-3">
                <div className="row">
                    <div className="col-md ps-0">
                        <div className="d-flex">
                            <div className="my-auto">
                                <img src={LOGO} className="logo" width="50" alt="" />
                            </div>
                            <div className="ms-4">
                                <div className="font-weight-bold">Austin Dudzik</div>
                                <p className="mb-0 small text-muted">Front-end Web Developer</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md pe-0 text-start text-md-end mt-3 mb-md-0">
                        <NewTab link={config.social.github} className="text-white">
                            <Icon type="fab" name="github" className="me-3" style={{ fontSize: '20px' }} />
                        </NewTab>
                        <NewTab link={config.social.linkedin} className="text-white">
                            <Icon type="fab" name="linkedin" className="me-3" style={{ fontSize: '20px' }} />
                        </NewTab>
                        <NewTab link={config.social.behance} className="text-white">
                            <Icon type="fab" name="behance" style={{ fontSize: '20px' }} />
                        </NewTab>
                    </div>
                </div>
                <div className="row mb-0 mb-4">
                    <div className="col-md ps-0">
                        <p className="mt-3 small mb-0">Made with ❤️ in Wisconsin</p>
                    </div>
                    <div className="col-md pe-0 text-start text-md-end">
                        <p className="small mt-0 mt-md-3 mb-2 mb-md-0">
                            &copy; {new Date().getFullYear()} Austin Dudzik, all rights reserved.
                        </p>
                    </div>
                </div>
            </div>

            <div className="navbar-nav mb-5 d-flex justify-content-between" style={{ flexShrink: '0', flexFlow: 'row wrap', alignItems: 'flex-end' }}>
                <Link to="/about" className="nav-item">About</Link>
                <Link to="/projects" className="nav-item">Projects</Link>
                <Link to="/experience" className="nav-item">Experience</Link>
                <Link to="/skills" className="nav-item">Skills</Link>
                <Link to="/resume" className="nav-item">Resume</Link>
                <Link to="/contact" className="nav-item">Contact</Link>
            </div>

        </div>
    )
}

export default Footer