export const yale_icon = require('./icon.png');
export const yale_01 = require('./01.png');
export const yale_02 = require('./02.png');
export const yale_03 = require('./03.png');
export const yale_04 = require('./04.png');
export const yale_05 = require('./05.png');
export const yale_06 = require('./06.png');
export const yale_07 = require('./07.png');
export const yale_08 = require('./08.png');
export const yale_09 = require('./09.png');
export const yale_10 = require('./10.png');
export const yale_11 = require('./11.png');