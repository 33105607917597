import { useState, useEffect } from "react";

function useOnScreen(ref, rootMargin = "0px") {
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {

        const item = ref.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
            }, {
                rootMargin,
            }
        );
        if (item) {
            observer.observe(item);
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            observer.unobserve(item);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return isIntersecting;
}

export default useOnScreen;