import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay } from 'swiper';
import 'swiper/css';

function Slider({ slider, index }) {
    return (
        <Swiper
            key={index}
            modules={[FreeMode, Autoplay]}
            className="overflow-hidden mb-3"
            loop={true}
            slidesPerView="auto"
            loopedSlides={5}
            spaceBetween={20}
            navigation={false}
            delay={0}
            speed={10000}
            centeredSlides={true}
            simulateTouch={false}
            draggable={false}
            autoplay={{
                delay: 0,
                disableOnInteraction: false,
                reverseDirection: !(index % 2 === 0)
            }}
        >
            {slider.map(([name, icon], index) => (
                <SwiperSlide key={index + Math.random()}>
                    <div className="card text-center p-0 px-3">
                        <div className="card-body">
                            <img src={icon} className="me-2" alt="" height="20" /> {name}
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default Slider