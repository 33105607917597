export const spark_icon = require('./icon.png');
export const spark_01 = require('./01.png');
export const spark_02 = require('./02.png');
export const spark_04 = require('./04.png');
export const spark_05 = require('./05.png');
export const spark_06 = require('./06.png');
export const spark_07 = require('./07.png');
export const spark_08 = require('./08.png');
export const spark_09 = require('./09.png');
export const spark_10 = require('./10.png');
export const spark_11 = require('./11.png');
export const spark_12 = require('./12.png');