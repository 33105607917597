export const bootstrap = require('./bootstrap.png');
export const cSharp = require('./c-sharp.png');
export const cloudflare = require('./cloudflare.png');
export const css = require('./css.png');
export const excel = require('./excel.png');
export const figma = require('./figma.png');
export const firebase = require('./firebase.png');
export const git = require('./git.png');
export const html = require('./html.png');
export const illustrator = require('./illustrator.png');
export const javascript = require('./javascript.png');
export const jquery = require('./jquery.png');
export const laravel = require('./laravel.png');
export const outlook = require('./outlook.png');
export const photoshop = require('./photoshop.png');
export const php = require('./php.png');
export const powerpoint = require('./powerpoint.png');
export const vue = require('./vue.png');
export const word = require('./word.png');
export const wordpress = require('./wordpress.png');
export const xd = require('./xd.png');