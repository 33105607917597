export const cert_css = require('./css.png');
export const cert_excel_2013 = require('./excel_2013.png');
export const cert_excel_2016 = require('./excel_2016.png');
export const cert_html = require('./html.png');
export const cert_javascript = require('./javascript.png');
export const cert_jquery = require('./jquery.png');
export const cert_outlook_2016 = require('./outlook_2016.png');
export const cert_php = require('./php.png');
export const cert_powerpoint_2013 = require('./powerpoint_2013.png');
export const cert_powerpoint_2016 = require('./powerpoint_2016.png');
export const cert_react = require('./react.png');
export const cert_sql = require('./sql.png');
export const cert_web_development = require('./web_development.png');
export const cert_word_2013 = require('./word_2013.png');
export const cert_word_2016_expert = require('./word_2016_expert.png');
export const cert_word_2016 = require('./word_2016.png');