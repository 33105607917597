import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LOGO from '../assets/img/logo-white.svg';
import Icon from '../components/Icon';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navigation({ hideShadow }) {

    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen(prev => !prev);
    }

    return (
        <Navbar variant="dark" bg={navOpen ? "dark" : ""} expand="md" className="text-white" style={!hideShadow ? {
            background: 'linear-gradient(180deg, #000000 0%, #00000050 50%, transparent 100%)',
            transition: '.4s'
            } : {}}>
            <Navbar.Brand as={Link} to="/" className="navbar-brand">
                <img src={LOGO} alt="Austin Dudzik logo" className="me-3" />
                <span>Austin Dudzik</span>
            </Navbar.Brand>
            <Navbar.Toggle
                onClick={toggleNav}
                children={<Icon type="far" name="bars" className="text-white" />}
                className="mt-2"
            />
            <Navbar.Collapse>
                <Nav className="ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <NavLink to="/about" className="nav-link">About</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/projects" className="nav-link">Projects</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/experience" className="nav-link">Experience</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/skills" className="nav-link">Skills</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/resume" className="nav-link">Resume</NavLink>
                    </li>
                    <li className="nav-item"></li>
                    <li className="nav-item">
                        <NavLink to="/contact" className="nav-link button_small outline">Contact</NavLink>
                    </li>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation