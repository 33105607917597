import { useTitle } from '../hooks/useTitle'
import { Link } from 'react-router-dom'
import Icon from '../components/Icon';
import Helmet from 'react-helmet'

function NotFound() {

    useTitle('404 - Page Not Found');

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="container">
                <h1 className="heading heading-lg ni" style={{ letterSpacing: 'normal' }}>404 Page<br /> Not Found</h1>
                <p className="text my-4">Apologies - the page you're looking for couldn't be found. It may have been
                    moved, deleted, or may have never even existed.</p>
                <p className="text mb-4">Instead, how about checking out my homepage?</p>
                <Link to="/" className="submit-button">
                    Continue to homepage <Icon type="far" name="long-arrow-right" className="ms-2" />
                </Link>
            </div>
        </>
    )
}

export default NotFound