import { Icon, NewTab } from '../components';
import { config } from '../config';
import { useTitle } from '../hooks/useTitle';

function ComingSoon() {

    useTitle('Coming soon');

    return (
        <div className="container text-start text-md-center px-4 px-md-0" id="coming-soon">
            <h1 className="heading heading-lg ni mb-4">Coming Soon.</h1>
            <p className="text mb-5">I'm currently working on something new that isn't quite ready yet.</p>

            <hr />

            <p className="text my-5">In the meantime, why not take a second to explore me elsewhere on the internet?</p>

            <div className="row social-items mt-4">
                <div className="col-12 col-md mb-3 text-start text-md-center">
                    <NewTab link={config.social.github}>
                        <Icon type="fab" name="github" className="me-2" /> GitHub
                    </NewTab>
                </div>
                <div className="col-12 col-md mb-3 text-start text-md-center">
                    <NewTab link={config.social.linkedin}>
                        <Icon type="fab" name="linkedin" className="me-2" /> LinkedIn
                    </NewTab>
                </div>
                <div className="col-12 col-md mb-3 text-start text-md-center">
                    <NewTab link={config.social.behance}>
                        <Icon type="fab" name="behance" className="me-2" /> Behance
                    </NewTab>
                </div>
                <div className="col-12 col-md-4 mb-3 text-start text-md-center">
                    <NewTab link={`mailto: ${config.social.email}`}>
                        <Icon name="envelope" className="me-2" /> {config.social.email}
                    </NewTab>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon