import { config } from '../config';
import { useTitle } from '../hooks/useTitle';
import Project from '../components/blocks/Project';

function Projects() {

  useTitle('Projects');

  return (
    <div className="container pt-5" id="page">

      <section className="my-5 mt-5 pt-2">

        <div className="text-start text-md-center mt-5">
          <h1 className="mb-3 fw-900 mt-0">Projects</h1>
          <p className="text mt-0 text-muted mb-0">I love building new things and have been designing, developing,
            and deploying new projects and applications over the years, ranging from full-fledged
            social media platforms to feedback tracking tools that help site owners track website
            feedback.</p>
        </div>

        <hr className="my-5" />

        <div className="row mt-4">
          {config.projects.map(({ name, slug, short_desc, cover, icon, lang }, index) => (
            <Project
              key={index}
              name={name}
              slug={slug}
              short_desc={short_desc}
              cover={cover}
              icon={icon}
              lang={lang}
            />
          ))}
        </div>
      </section>
    </div>
  )
}

export default Projects