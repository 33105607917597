export const ericCarle_icon = require('./icon.png');
export const ericCarle_01 = require('./01.png');
export const ericCarle_02 = require('./02.png');
export const ericCarle_03 = require('./03.png');
export const ericCarle_04 = require('./04.png');
export const ericCarle_05 = require('./05.png');
export const ericCarle_06 = require('./06.png');
export const ericCarle_07 = require('./07.png');
export const ericCarle_08 = require('./08.png');
export const ericCarle_09 = require('./09.png');
export const ericCarle_10 = require('./10.png');
export const ericCarle_11 = require('./11.png');
export const ericCarle_12 = require('./12.png');