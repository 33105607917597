import { useRef, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon, NewTab } from '../components';
import { config } from '../config';
import FancyBox from '../hooks/Fancybox';
import Testimonial from '../components/blocks/Testimonial';
import Project from '../components/blocks/Project';
import HomeSection from '../components/blocks/HomeSection';
import Slider from '../components/blocks/Slider';
import useOnScreen from "../hooks/useOnScreen";

function Home({ setHideShadow }) {

    const ref = useRef();

    const onScreen = useOnScreen(ref);

    useEffect(() => {
        setHideShadow(onScreen);
    })

    return (
        <>
            <div className="bg-gradient pt-5 d-min-vh-100" ref={ref}>
                <Container className="intro">
                    <h1>Austin Dudzik</h1>
                    <h2 className="mb-0 mb-md-4">Maker. Designer. Developer.</h2>

                    <p className="my-3 border-bottom pb-4">Ambitious and talented front-end web developer with 5+
                        years of self-taught experience. Driven to deliver meaningful results, on-time and with
                        great attention to detail. Striving to create positive user experiences that capture
                        true potential.</p>

                    <div className="d-flex flex-wrap social-items mt-4">
                        <NewTab link={config.social.behance} className="me-4">
                            <Icon name="behance" type="fab" className="me-2" /> Behance
                        </NewTab>
                        <NewTab link={config.social.github} className="me-4">
                            <Icon name="github" type="fab" className="me-2" /> GitHub
                        </NewTab>
                        <NewTab link={config.social.linkedin} className="me-4">
                            <Icon name="linkedin" type="fab" className="me-2" /> LinkedIn
                        </NewTab>
                        <NewTab link={`mailto:${config.social.email}`} className="me-4">
                            <Icon name="envelope" className="me-2" /> {config.social.email}
                        </NewTab>
                    </div>
                </Container>
            </div>


            <div className="container pt-0 pt-md-5" id="page">

                <HomeSection
                    lineOne="The proper skill set to succeed."
                    lineTwo="Coupled with years of experience."
                    subheading="After years of developing, I've established a strong, flexible, and growing skill set in dozens of development languages, tools, and frameworks, ranging from database management to UI and prototyping."
                    children={
                        <>
                            <p className="small mb-3">Tools & Languages</p>

                            {config.slides.map((slider, index) => <Slider index={index} slider={slider} />)}

                            <div className="d-flex justify-content-center my-4">
                                <Link to="/skills" className="text-white">
                                    <div className="bg-gradient ni p-1 rounded">
                                        <div className="button-3 hover-grad ni px-4 py-2">
                                            Explore entire skill set <Icon type="far" name="long-arrow-right" className="ms-2" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>
                    }
                />

                <div className="divider" />

                <HomeSection
                    lineOne="The right experience to show."
                    lineTwo="Driven to deliver meaningful results."
                    subheading="As a front-end web developer, I've gotten the opportunity to work with some of the most innovative and trusted companies, in helping to contribute to the development, design, and usability of new web and digital experiences. My work has been proven successful time and time again."
                    children={
                        <div className="bg-gradient p-1 ni mb-5 rounded">
                            <div className="card p-2 p-md-4 ni">
                                <div className="card-body">
                                    {config.experience[0].img && (
                                        <img src={config.experience[0].img} className="rounded mb-2"
                                            width="50" alt="" />
                                    )}
                                    <div className="d-none d-md-flex justify-content-between mb-2">
                                        <div>
                                            <h4 className="mb-2">{config.experience[0].title}</h4>
                                            <p className="x-small text-muted mb-0">{config.experience[0].workplace}</p>
                                        </div>
                                        <div>
                                            <p className="small mb-2 text-end">
                                                {config.experience[0].start}
                                                - {config.experience[0].end}
                                            </p>
                                            <p className="small text-end">{config.experience[0].location}</p>
                                        </div>
                                    </div>
                                    <div className="d-block d-md-none mb-2">
                                        <div>
                                            <h4 className="mb-2">{config.experience[0].title}</h4>
                                            <p className="x-small text-muted mb-0">{config.experience[0].workplace}</p>
                                        </div>
                                        <div>
                                            <p className="small mt-2 mb-2">
                                                {config.experience[0].start}
                                                - {config.experience[0].end}
                                            </p>
                                            <p className="small">{config.experience[0].location}</p>
                                        </div>
                                    </div>
                                    {config.experience[0].skills.map((skill, index) => (
                                        <div className="d-flex" key={index}>
                                            <div className="me-2 ni">✨</div>
                                            <p className="mb-2">{skill}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                />

                <div className="divider" />

                <HomeSection
                    lineOne="Unique brand experiences."
                    lineTwo="Designed to adapt and evolve."
                    subheading="Besides working with code, I've utilized my skills in UI and
                    UX design to create adaptive and flavorful brand logos and design systems that
                    present as modern, unique, and legible. The work I create is well-thought-out and
                    tuned to perfection."
                    children={
                        <div className="mt-5">
                            <div className="row">
                                <FancyBox options={config.fancybox}>
                                    {config.logos.map(([name, icon], index) => (
                                        <div className="col-6 col-md-3 pb-4" key={index}>
                                            <button className="link-btn" data-src={icon} data-fancybox="logos">
                                                <img className="img-fluid rounded" src={icon} alt={`${name} logo`} />
                                            </button>
                                        </div>
                                    ))}
                                </FancyBox>
                            </div>
                        </div>
                    }
                />

                <div className="divider" />

                <HomeSection
                    lineOne="Passionate about code."
                    lineTwo="Explore my recent projects."
                    subheading="I love building new things and have been designing, developing,
                    and deploying new projects and applications over the years, ranging from full-fledged
                    social media platforms to feedback tracking tools that help site owners track website
                    feedback."
                    children={
                        <div className="row">
                            {config.projects.slice(0, 4).map(({ name, slug, short_desc, cover, icon, lang }, index) => (
                                <Project
                                    key={index}
                                    name={name}
                                    slug={slug}
                                    short_desc={short_desc}
                                    cover={cover}
                                    icon={icon}
                                    lang={lang}
                                />
                            ))}
                        </div>
                    }
                />

                <div className="divider" />

                <HomeSection
                    lineOne="Hear what they think."
                    lineTwo="Read the testimonials."
                    subheading="By others, I'm most commonly known for my strong work ethic and dedication. I'm always up for a good challenge and can easily adapt to new development languages, projects, and environments. Don't just take it from me, read what others really have to say."
                    children={
                        <div className="mt-5">
                            {config.testimonials.map(({ name, color, quote, title, company }, i) => (
                                <Testimonial
                                    key={i}
                                    name={name}
                                    color={color}
                                    quote={quote}
                                    title={title}
                                    company={company}
                                />
                            ))}
                        </div>
                    }
                />

            </div>
        </>
    )
}

export default Home