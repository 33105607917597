export const nova_icon = require('./icon.png');
export const nova_01 = require('./01.png');
export const nova_02 = require('./02.png');
export const nova_03 = require('./03.png');
export const nova_04 = require('./04.png');
export const nova_05 = require('./05.png');
export const nova_06 = require('./06.png');
export const nova_07 = require('./07.png');
export const nova_08 = require('./08.png');
export const nova_09 = require('./09.png');
export const nova_10 = require('./10.png');
export const nova_11 = require('./11.png');